<template>
  <div
    class="hce-image-gallery no-container-gutters"
    data-dm="component.image-gallery"
  >
    <div class="hce-image-gallery__carousel container">
      <div class="hce-image-gallery__header">
        <h3 class="mb-0">
          {{ dictionary.title }}
        </h3>
        <div class="hce-image-gallery__controls-md">
          <hce-control-arrows
            :next-disabled="isAtEnd"
            :prev-disabled="isAtBeginning"
            @next="nextCarouselSlide"
            @prev="prevCarouselSlide"
          />
        </div>
      </div>

      <div class="hce-image-gallery__slides slides-container">
        <div
          ref="swiper-container"
          class="swiper-container"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(image, index) in images"
              :key="index"
              data-dm="btn-image-open"
              class="hce-image-gallery__slide swiper-slide"
            >
              <img
                :src="image.url"
                :alt="image.alt"
              >
              <button
                class="hce-image-gallery__zoom-in"
                @keypress="onSelectImage"
              >
                <svg
                  class="icon"
                  role="img"
                  :title="'Zoom in image ' + (index + 1)"
                >
                  <use xlink:href="/assets/icons/ui.svg#zoom-in" />
                </svg>
              </button>
            </div>
          </div>
          <div
            :class="{ 'is-hidden': isAtEnd }"
            class="hce-image-gallery__scroll-shadow"
          />
        </div>
      </div>

      <div class="hce-image-gallery__controls-sm">
        <hce-control-arrows
          :next-disabled="isAtEnd"
          :prev-disabled="isAtBeginning"
          @next="nextCarouselSlide"
          @prev="prevCarouselSlide"
        />
      </div>
    </div>

    <div
      v-show="showOverlay"
      id="dialog"
      role="dialog"
      aria-labelledby="carousel dialog"
      aria-modal="true"
      class="image-gallery-overlay"
    >
      <div>
        <button
          ref="dialog-close-btn"
          data-dm="btn-image-close"
          class="image-gallery-overlay__close"
          aria-label="close dialog"
          @click="closeOverlay"
        >
          <svg
            class="icon"
            role="img"
            title="Close overlay"
          >
            <use xlink:href="/assets/icons/ui.svg#close" />
          </svg>
        </button>
        <button
          ref="prev-overlay"
          class="swiper-button-prev swiper-button-white"
        />
        <button
          ref="next-overlay"
          class="swiper-button-next swiper-button-white"
        />
      </div>

      <div
        ref="swiper-overlay-container"
        class="swiper-container"
      >
        <div
          class="swiper-wrapper"
          @click="handleClickOutsideCarouselImage"
        >
          <div
            v-for="(image, index) in images"
            :key="index"
            class="image-gallery-overlay__slide swiper-slide"
          >
            <img
              :src="image.url"
              :alt="image.alt"
            >
          </div>
        </div>
        <div
          ref="overlay-pagination"
          class="swiper-pagination swiper-pagination-white"
        />
        <div class="swiper-pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, viewport } from 'foundation-theming';
import HceControlArrows from './HceControlArrows.vue';

export default {
  components: { HceControlArrows },
  props: {
    images: {
      type: Array,
      required: true,
    },
    dictionary: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiper: null,
      overlaySwiper: null,
      selectedImage: {},
      isOverlayInitialized: false,
      showOverlay: false,
    };
  },
  computed: {
    isAtEnd() {
      return this.swiper ? this.swiper.isEnd : false;
    },
    isAtBeginning() {
      return this.swiper ? this.swiper.isBeginning : true;
    },
  },
  watch: {
    /**
     * Prevent scrolling when the overlay is shown
     */
    showOverlay(newValue) {
      if (newValue) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
  },
  mounted() {
    this.initSwiper();
  },
  created() {
    /**
     * Handle closing the overlay with Escape key
     */
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        if (this.showOverlay) this.showOverlay = false;
      }
    });
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(this.$refs['swiper-container'], {
        slidesPerView: 'auto',
        preventClicks: false,
        freeMode: true,
        grabCursor: true,
        a11y: {
          enabled: true,
          prevSlideMessage: 'Previous slide',
          nextSlideMessage: 'Next slide',
          firstSlideMessage: 'This is the first slide',
          lastSlideMessage: 'This is the last slide',
        },
        on: {
          click: this.onSelectImage,
        },
      });
    },
    initOverlaySwiper() {
      this.overlaySwiper = new Swiper(this.$refs['swiper-overlay-container'], {
        centeredSlides: true,
        autoResize: false,
        loop: true,
        keyboard: {
          enabled: true,
        },
        navigation: {
          nextEl: this.$refs['next-overlay'],
          prevEl: this.$refs['prev-overlay'],
        },
        pagination: {
          el: this.$refs['overlay-pagination'],
          clickable: true,
        },
        on: {
          slideChange: this.onOverlaySlideChange,
        },
      });
    },
    nextCarouselSlide() {
      this.swiper.slideNext();
    },
    prevCarouselSlide() {
      this.swiper.slidePrev();
    },
    /**
     * Handle main image selection by intializing or
     * updating the overlay with the correct slide
     */
    onSelectImage() {
      this.showOverlay = true;

      this.$nextTick(() => {
        if (this.isOverlayInitialized) {
          this.overlaySwiper.update();
        } else {
          this.initOverlaySwiper();
          this.isOverlayInitialized = true;
        }
        this.goToOverlaySlide();
        this.$refs['dialog-close-btn'].focus();
      });
    },
    /**
     * Use the selected slide index to show same image in overlay
     */
    goToOverlaySlide() {
      const { clickedIndex } = this.swiper;
      this.selectedImage = this.images[clickedIndex];
      this.overlaySwiper.slideToLoop(clickedIndex, 0);
    },
    /**
     * Change the original slider position to the current overlay slide
     */
    onOverlaySlideChange() {
      const { realIndex } = this.overlaySwiper;
      this.swiper.slideTo(realIndex);
    },
    /**
     * On large viewport close the overlay on click outside image
     * @param {Event} target clicked element
     */
    handleClickOutsideCarouselImage({ target }) {
      if (viewport.is('>=lg')) {
        if (target.tagName.toLowerCase() !== 'img') {
          this.closeOverlay();
        }
      }
    },
    closeOverlay() {
      this.showOverlay = false;
    },
  },
};
</script>
