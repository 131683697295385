<template>
  <table class="hce-product-table">
    <thead>
      <tr>
        <th
          class="label"
          colspan="2"
        >
          {{ category.name }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in category.items"
        :key="index"
      >
        <td>{{ item.label }}</td>
        <td>{{ calculateUnit(item.values) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    category: { type: Object, required: true, default: () => {} },
    calculateUnit: { type: Function, required: true },
  },
};
</script>
