<template>
  <div
    class="hce-dealer-map"
  />
</template>

<script>
import GoogleMapsLoader from 'google-maps';

const MAP_MARKER = '/assets/images/map/marker.svg';
const MAP_MARKER_ACTIVE = '/assets/images/map/marker-active.svg';
const ZOOM_LEVEL_INITIAL = 4;
const ZOOM_LEVEL_DEALER = 10;
const ZOOM_LEVEL_COUNTRY = 6;

export default {
  props: {
    dictionary: { type: Object, required: true, default: () => {} },
    isDisabled: { type: Boolean, required: true, default: false },
    locations: { type: Array, required: true, default: () => [] },
    selectedDealer: { type: Object, required: true, default: () => {} },
    selectedCountry: { type: String, required: true, default: '' },
    userCountry: { type: String, required: true, default: '' },
    type: { type: String, required: false, default: '' },
  },
  data() {
    return {
      map: null,
      markers: [],
    };
  },
  watch: {
    locations(newLocations) {
      this.loadMarkers(newLocations);
    },
    selectedDealer(dealer) {
      this.highlightMarker(dealer);
    },
    selectedCountry(country) {
      this.centerCountry(country);
    },
    userCountry(country) {
      this.centerCountry(country);
    },
  },
  created() {
    GoogleMapsLoader.KEY = 'AIzaSyChfqIRjRy1LbA5J4EUsKuCpNpAXovJsQk';
    GoogleMapsLoader.VERSION = 'quarterly';

    GoogleMapsLoader.load((google) => {
      this.map = new google.maps.Map(this.$el, {
        disableDefaultUI: true,
        zoomControl: true,
      });

      this.openedInfoWindow = null;
      this.selectedMarker = null;

      /**
       * Reset and paint markers based on location coordinates given
       * @param {array} locations to add associated markers
       * @returns {undefined}
       */
      this.loadMarkers = (locations) => {

        console.dir(this.type);

        // Removes the markers from the map
        this.markers.forEach(marker => marker.setMap(null));
        // Reset the markers
        this.currentWindow = null;
        locations.forEach(({
          location, guid, name, url,
        }) => {
          // only add marker if lat long is not empty
          if (location.lat && location.lng) {
            const marker = new google.maps.Marker({
              position: location,
              map: this.map,
              icon: this.getIconImage(MAP_MARKER),
              optimized: false,
              guid,
            });
            const contentString = `${`${'<div id="content">'
            + '<div id="siteNotice">'
            + '</div>'
            + '<h1 id="firstHeading" class="firstHeading">'}${name}</h1>`
            + '<div id="bodyContent">'
            + `<p>${location.address}</p>`
            + `${this.type !== 'detail' ? `<p class="mt-3"><a href=${url} class="btn btn-primary btn-sm" role="button">${this.dictionary.dealerContactButton}</a></p>` : ''}`
            + '</div>'
            }</div>`;
            const infoWindow = new google.maps.InfoWindow({
              content: contentString,
            });

            marker.addListener('click', () => {
              if (this.openedInfoWindow) {
                this.openedInfoWindow.close();
              }
              if (this.selectedMarker) {
                this.selectedMarker.setIcon(this.getIconImage(MAP_MARKER));
              }
              this.map.setZoom(ZOOM_LEVEL_DEALER);
              this.map.setCenter(marker.getPosition());
              infoWindow.open({
                anchor: marker,
                map: this.map,
                shouldFocus: false,
              });
              marker.setIcon(this.getIconImage(MAP_MARKER_ACTIVE));
              this.selectedMarker = marker;
              this.openedInfoWindow = infoWindow;
            });
            this.markers.push(marker);
          }
        });

      };

      this.clickMarker = (m) => {
        google.maps.event.trigger(m, 'click');
      };

      /**
       * Create an object to use as map icon with fixed size (IE11 fix)
       * @param {string} url that links to icon image
       * @returns {object} icon
       */
      this.getIconImage = url => ({
        url,
        scaledSize: new google.maps.Size(30, 34),
      });

      const geocoder = new google.maps.Geocoder();

      /**
       * Based on country name center and zoom to the location on map
       * @param {string} country to highlight
       * @returns {undefined}
       */
      this.centerCountry = (country, initial = false) => {
        geocoder.geocode({ address: country }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            this.map.setZoom(initial ? ZOOM_LEVEL_INITIAL : ZOOM_LEVEL_COUNTRY);
            this.map.panTo(results[0].geometry.location);
          }
        });
      };


      this.centerCountry(this.selectedCountry);
      this.centerCountry(this.userCountry, true);
      this.loadMarkers(this.locations);
      this.highlightMarker(this.selectedDealer);

    });
  },

  methods: {
    /**
     * Find the marker with correct dealer guid and change the icon
     * @param {object} dealer to highlight associated marker
     * @returns {undefined}
     */
    highlightMarker(dealer) {
      this.markers.forEach((marker) => {
        if (marker.guid === dealer.guid) {
          this.clickMarker(marker);
        }
      });
    },
  },
};
</script>
