<template>
  <div
    :class="{ 'hce-toggle--large': size === 'large' }"
    class="hce-toggle"
  >
    <p
      v-if="labelOff"
      :class="{ 'is-highlighted': !value }"
      class="hce-toggle__label"
    >
      {{ labelOff }}
    </p>
    <input
      :id="id"
      aria-label="Cookie not set, click to enable"
      type="checkbox"
      class="hce-toggle__checkbox"
      :checked="value"
      @change="onToggle"
    >
    <div class="hce-toggle__wrapper">
      <label
        ref="handle"
        :for="id"
        class="hce-toggle__handle"
      />
    </div>
    <p
      v-if="labelOn"
      :class="{ 'is-highlighted': value }"
      class="hce-toggle__label"
    >
      {{ labelOn }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      validator: v => ['regular', 'large'].includes(v),
      default: 'regular',
    },
    labelOn: {
      type: String,
      default: undefined,
    },
    labelOff: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    onToggle(event) {
      setTimeout(() => this.$emit('input', event.target.checked), 300);
    },
  },
};
</script>
