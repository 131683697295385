var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hce-article-overview",
      attrs: { "data-dm": "component.article-overview" },
    },
    [
      _c("header", { staticClass: "overview-header" }, [
        _c("div", { staticClass: "container" }, [
          _c("h1", { staticClass: "overview-title display-1" }, [
            _vm._v("\n        " + _vm._s(_vm.dictionary.title) + "\n      "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("main", [
        _c("div", { staticClass: "half-background" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "section",
              { staticClass: "highlighted-cards" },
              _vm._l(
                _vm.overview.results.highlightedArticles,
                function (article, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "col-md-6" },
                    [
                      _c("hce-article-card", {
                        attrs: { article: article, type: "highlighted" },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "transition-group",
              {
                directives: [{ name: "masonry", rawName: "v-masonry" }],
                staticClass: "regular-cards-masonry",
                attrs: {
                  name: "cards",
                  tag: "section",
                  "item-selector": ".masonry-item",
                  "transition-duration": "0",
                },
              },
              _vm._l(_vm.overview.results.articles, function (article, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      { name: "masonry-tile", rawName: "v-masonry-tile" },
                    ],
                    key: index,
                    staticClass: "masonry-item",
                  },
                  [
                    _c("hce-article-card", {
                      attrs: { article: article, type: "regular" },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.overview.results.articles.length <
            _vm.overview.results.articleCount
              ? _c("div", { staticClass: "load-more-cards" }, [
                  _c(
                    "button",
                    {
                      staticClass: "load-more-button btn btn-secondary btn-lg",
                      attrs: { role: "button" },
                      on: { click: _vm.loadArticlesForNextPage },
                    },
                    [
                      _c("span", { staticClass: "btn__label" }, [
                        _vm._v(_vm._s(_vm.dictionary.loadMore)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }