export const onApiReady = new Promise((resolve) => {
  window.onYouTubeIframeAPIReady = () => resolve();
});

export const tryToLoadScript = () => {
  const src = 'https://www.youtube.com/iframe_api';
  const hasScript = Array.from(document.querySelectorAll('script')).some(script => script.src === src);

  if (hasScript) {
    return false;
  }

  const tag = document.createElement('script');
  tag.src = src;
  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(tag, firstScript);
  return true;
};
