var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hce-toggle",
      class: { "hce-toggle--large": _vm.size === "large" },
    },
    [
      _vm.labelOff
        ? _c(
            "p",
            {
              staticClass: "hce-toggle__label",
              class: { "is-highlighted": !_vm.value },
            },
            [_vm._v("\n    " + _vm._s(_vm.labelOff) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        staticClass: "hce-toggle__checkbox",
        attrs: {
          id: _vm.id,
          "aria-label": "Cookie not set, click to enable",
          type: "checkbox",
        },
        domProps: { checked: _vm.value },
        on: { change: _vm.onToggle },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "hce-toggle__wrapper" }, [
        _c("label", {
          ref: "handle",
          staticClass: "hce-toggle__handle",
          attrs: { for: _vm.id },
        }),
      ]),
      _vm._v(" "),
      _vm.labelOn
        ? _c(
            "p",
            {
              staticClass: "hce-toggle__label",
              class: { "is-highlighted": _vm.value },
            },
            [_vm._v("\n    " + _vm._s(_vm.labelOn) + "\n  ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }