/* eslint-disable no-param-reassign */
import { CookieConsent } from 'foundation-theming';

export default {
  state: {
    isConsentGiven: CookieConsent.isConsentGiven(),
    gdpr: CookieConsent.getGdprName(),
  },
  mutations: {
    setGdpr(state, value) {
      state.isConsentGiven = true;
      state.gdpr = value;
    },
  },
  getters: {
    getIsConsentGiven: state => state.isConsentGiven,
    getGdpr: state => state.gdpr,
  },
};
