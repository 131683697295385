var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hce-dealer-list" }, [
    _c("div", { staticClass: "hce-dealer-list__dealer-count" }, [
      _c("span", { staticClass: "label", attrs: { for: "dealer-count" } }, [
        _vm._v("\n      " + _vm._s(_vm.statusLabel) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.dealers, function (dealer, index) {
        return _c(
          "li",
          {
            key: dealer.guid,
            ref: "dealers",
            refInFor: true,
            staticClass: "hce-dealer-list__dealer",
            attrs: { "data-dm": "component.dealerlocator.dealer" },
          },
          [
            _c("div", { staticClass: "hce-dealer-list__dealer-wrapper" }, [
              _c("button", {
                staticClass: "collapse-button",
                attrs: {
                  "aria-expanded": (
                    _vm.selectedDealer.guid === dealer.guid
                  ).toString(),
                },
                on: {
                  click: function ($event) {
                    return _vm.dealerCollapsibleHandle(
                      index,
                      _vm.selectedDealer.guid !== dealer.guid
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "hce-dealer-list__dealer-main" }, [
                _c(
                  "h3",
                  { staticClass: "h6 hce-dealer-list__dealer-heading" },
                  [
                    _vm._v(
                      "\n            " + _vm._s(dealer.name) + "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "rte",
                    attrs: { "data-dm": "component.richtext" },
                  },
                  [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(dealer.location.address) },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._m(0, true),
              _vm._v(" "),
              _c("a", {
                staticClass: "hce-dealer-list__full-link",
                attrs: { href: dealer.url },
              }),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hce-dealer-list__dealer-toggle" }, [
      _c("span", { staticClass: "collapse-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }