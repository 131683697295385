<template>
  <div
    class="hce-image-slider no-container-gutters"
    data-dm="component.image-slider"
  >
    <div class="hce-image-slider__carousel container">
      <div class="hce-image-slider__header">
        <h3 class="mb-0">
          {{ dictionary.title }}
        </h3>
      </div>
      <div class="hce-image-slider__carousel-inner">
        <div
          ref="image-carousel"
          class="glide"
        >
          <div
            class="glide__track"
            data-glide-el="track"
          >
            <ul class="glide__slides">
              <li
                v-for="(image, index) in images"
                :key="index"
                class="glide__slide"
              >
                <div class="glide__slide-wrapper">
                  <img
                    :src="image.url"
                    :alt="image.alt"
                  >
                </div>
              </li>
            </ul>
          </div>
          <div
            class="glide__arrows"
            data-glide-el="controls"
          >
            <button
              class="glide__arrow glide__arrow--left"
              data-glide-dir="<"
            >
              <span class="icon icon--arrow-left">
                <svg
                  role="img"
                  title="previous slide"
                >
                  <use xlink:href="/assets/icons/ui.svg#arrow-left" />
                </svg>
              </span>
            </button>
            <button
              class="glide__arrow glide__arrow--right"
              data-glide-dir=">"
            >
              <span class="icon icon--arrow-right">
                <svg
                  role="img"
                  title="next slide"
                >
                  <use xlink:href="/assets/icons/ui.svg#arrow-right" />
                </svg>
              </span>
            </button>
          </div>
          <div class="glide__bullets--wrapper d-flex">
            <label class="mr-2">IMAGE</label>
            <div
              class="glide__bullets"
              data-glide-el="controls[nav]"
            >
              <button
                v-for="(image, index) in images"
                :key="index"
                :data-glide-dir="index"
                class="glide__bullet label mr-2"
              >
                {{ index + 1 }}
              </button>
            </div>
            <label ref="counted-images" class="js-counted-imgs" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Glide } from 'foundation-theming';

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    dictionary: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    this.initCarousel();
    this.fillBulletsContainer();
  },
  methods: {
    initCarousel() {
      // const glideContainer = document.querySelector('.glide');
      const carousel = new Glide(this.$refs['image-carousel'], {
        type: 'carousel',
        focusAt: 'center',
        perView: 4,
        breakpoints: {
          991: {
            perView: 3,
          },
          576: {
            perView: 1,
          },
        },
        afterTransition: (event) => {
          console.log(carousel.index, event.current); // the current slide number
        },
      });
      carousel.mount();
    },
    fillBulletsContainer() {
      const glideContainer = this.$refs['image-carousel'];
      // Get number of images in glidejs carousel
      const totalBullets = glideContainer.querySelectorAll('.glide__slide:not(.glide__slide--clone)').length;
      const totalBulletsContainer = this.$refs['counted-images'];
      const string = `/ ${totalBullets}`;
      totalBulletsContainer.innerHTML = string;
    },
  },
};
</script>
