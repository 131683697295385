/* eslint-disable import/order */
import { Vue, Vuex } from 'foundation-theming';
import { VueMasonryPlugin } from 'vue-masonry';
// Vue components
import SitecoreExpEditorPlugin from 'vue-scexpeditor';
import cookie from './cookie.store';
import HceArticleOverview from 'hce-article-overview';
import HceDealerLocator from 'hce-dealer-locator';
import HceProductSpecifications from 'hce-product-specifications';
import HceVideo from 'hce-video';
import HceImageGallery from 'hce-image-gallery';
import HceImageSlider from 'hce-image-slider';

// Vue config
Vue.config.devtools = true;
Vue.use(Vuex);
Vue.use(VueMasonryPlugin);

// Sitecore Experience Editor Support fix
// By adding 'v-pre' attributes to Sitecore's <code> tags, we make Vue ignore them
// After that we use the SitecoreExpEditorPlugin to enable Exp Editor support for Vue
if (typeof Sitecore !== 'undefined') {
  document
    .querySelectorAll('code[type="text/sitecore"]')
    .forEach(el => el.setAttribute('v-pre', ''));
  Vue.use(SitecoreExpEditorPlugin);
}

// eslint-disable-next-line
new Vue({
  el: '#app',
  store: new Vuex.Store({
    modules: { cookie },
  }),
  components: {
    HceArticleOverview,
    HceDealerLocator,
    HceProductSpecifications,
    HceVideo,
    HceImageGallery,
    HceImageSlider,
  },
  created() {
    // store style tags in hbs files
    this.styleTagNodeList = document.querySelector(this.$options.el).querySelectorAll('style');
  },
  mounted() {
    // eslint-disable-next-line max-len
    for (let i = 0; i < this.styleTagNodeList.length; ++i) this.$el.appendChild(this.styleTagNodeList[i]);
  },
});
