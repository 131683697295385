var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hce-product-specifications",
      attrs: { "data-dm": "component.product-specifications" },
    },
    [
      _c("div", { staticClass: "hce-product-specifications__container" }, [
        _c(
          "div",
          {
            ref: "wrapper",
            staticClass: "hce-product-specifications__wrapper",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "hce-product-specifications__label hce-product-specifications__scroll-hint",
                class: { "is-hidden": _vm.isScrolling },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.dictionary.scrollHint) +
                    "\n        "
                ),
                _c("span", { staticClass: "icon icon--arrow-right" }, [
                  _c(
                    "svg",
                    { attrs: { role: "icon", title: "icon: arrow-right" } },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href": "/assets/icons/ui.svg#arrow-right",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "hce-product-specifications__introductory-tables",
              },
              _vm._l(_vm.remainingCategories, function (category) {
                return _c("hce-product-table", {
                  key: category.name,
                  attrs: {
                    "calculate-unit": _vm.getCorrectUnit,
                    category: category,
                  },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "cut-corner",
                attrs: {
                  viewBox: "0 0 15 24",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("polygon", { attrs: { points: "15 0, 15 24, 0 24" } }),
                _vm._v(" "),
                _c("line", { attrs: { x1: "0", y1: "24", x2: "15", y2: "0" } }),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }