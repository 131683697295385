<!-- eslint-disable max-len -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div
    class="hce-dealer-locator"
    :class="{ 'detail': type === 'detail' }"
    data-dm="component.dealerlocator"
  >
    <div
      v-if="type !== 'detail'"
      class="main"
    >
      <div
        class="introduction is-startscreen"
      >
        <div
          class="rte"
          data-dm="component.richtext"
        >
          <slot name="introduction" />
          <p class="introduction__text">
            {{ dictionary.introductionText }}
          </p>
        </div>
      </div>

      <form v-if="type === 'overview'">
        <div class="start-filter">
          <div class="country-background">
            <!-- v-model="filters.country" -->
            <div class="dropdown">
              <button id="dropdownMenuButton" class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                {{ dictionary.countrySelectLabel | selected }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a v-for="c in countriesWithDealer" :key="c.name" class="dropdown-item" :href="c.url">{{ c.name }}</a>
              </div>
            </div>
          </div>
        </div>
      </form>

      <a
        v-if="type === 'country'"
        :href="dictionary.countrySelectionLink"
        role="button"
        class="btn btn-link
           btn-lg
           btn-link--alt
           btn-icon
           p-0
           justify-content-start
          "
      >
        <span class="icon icon--arrow-left icon--left">
          <svg
            role="img"
            title="icon: arrow-left"
          >
            <use xlink:href="/assets/icons/ui.svg#arrow-left" />
          </svg>
        </span>
        <span class="btn__label"> {{ dictionary.backToCountrySelection }}</span>
      </a>

      <div
        v-if="type === 'country'"
        class="dealers"
      >
        <hce-dealer-list
          :dealers="filteredDealers"
          :dictionary="dictionary"
          :selected-dealer="selectedDealer"
          :status-label="dealerStatus"
          @select="onDealerSelect"
        />
      </div>
    </div>
    <div
      v-if="type !== 'detail'"
      class="col-md-6 map-section"
    >
      <hce-dealer-map
        :locations="dealerLocations"
        :dictionary="dictionary"
        :selected-dealer="selectedDealer"
        :selected-country="filters.country"
        :user-country="userCountry"
      />
    </div>

    <div v-if="type === 'detail'">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div
              class="introduction is-startscreen"
            >
              <div
                class="rte"
                data-dm="component.richtext"
              >
                <slot name="introduction" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <a
              :href="dictionary.dealerSelectionLink"
              role="button"
              class="btn btn-link
           btn-lg
           btn-link--alt
           btn-icon
           p-0
           justify-content-start
           mb-4
          "
            >
              <span class="icon icon--arrow-left icon--left">
                <svg
                  role="img"
                  title="icon: arrow-left"
                >
                  <use xlink:href="/assets/icons/ui.svg#arrow-left" />
                </svg>
              </span>
              <span class="btn__label">{{ dictionary.backToDealerSelection }}</span>
            </a>
            <div
              class="dealer-locator-detail"
            >
              <h3 class="h6 hce-dealer-list__dealer-heading">
                {{ selectedDealer.name }}
              </h3>
              <div
                class="rte"
                data-dm="component.richtext"
              >
                <p v-html="selectedDealer.location.address" />
              </div>
              <p>
                <a
                  :href="`tel:${selectedDealer.contact.phoneNumberWithoutSpaces}`"
                  class="btn btn-link btn-link--alt btn-sm"
                  role="button"
                >
                  <span class="btn__label">
                    {{ selectedDealer.contact.phoneNumber }}
                  </span>
                </a>
              </p>
              <p>
                <a
                  v-if="selectedDealer.contact.website"
                  :href="selectedDealer.contact.website"
                  class="btn btn-link btn-link--alt btn-sm"
                  role="button"
                  target="_blank"
                >
                  <span v-if="selectedDealer.contact.websiteText" class="btn__label">
                    {{ selectedDealer.contact.websiteText }}
                  </span>
                  <span v-else class="btn__label">
                    {{ selectedDealer.contact.website }}
                  </span>
                </a>
              </p>
              <p>
                <a
                  :href="`mailto:${selectedDealer.contact.email}`"
                  class="btn btn-link btn-link--alt btn-sm"
                  role="button"
                >
                  <span class="btn__label">
                    {{ selectedDealer.contact.email }}
                  </span>
                </a>
              </p>
              <div class="mt-4">
                <p class="mb-2">
                  <a
                    v-if="selectedDealer.location.lat && selectedDealer.location.lng && selectedDealer.location.lat !== 0.0
                      && selectedDealer.location.lng !== 0.0"
                    :href="createDirectionsLink(selectedDealer)"
                    class="btn btn-secondary btn-sm"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span class="btn__label">
                      {{ dictionary.dealerDirections }}
                    </span>
                  </a>
                </p>
                <p>
                  <a
                    :href="`mailto:${selectedDealer.contact.email}`"
                    class="btn btn-secondary btn-sm"
                    role="button"
                  >
                    {{ dictionary.dealerContactButton }}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <hce-dealer-map
              :locations="dealerLocations"
              :dictionary="dictionary"
              :selected-dealer="selectedDealer"
              :selected-country="filters.country"
              :user-country="userCountry"
              :type="type"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { viewport, lodash, Cookies } from 'foundation-theming';
import HceDealerList from './HceDealerList.vue';
import HceDealerMap from './HceDealerMap.vue';
// import HcePostalCodeValidator from './HcePostalCodeValidator.vue';

export default {
  components: {
    HceDealerList,
    HceDealerMap,
  },
  props: {
    countries: { type: Array, required: true, default: () => [] },
    country: { type: String, required: false, default: () => '' },
    dealers: { type: Array, required: true, default: () => [] },
    dealer: { type: Object, required: false, default: () => {} },
    dictionary: { type: Object, required: true, default: () => {} },
    productCategories: { type: Array, required: true, default: () => [] },
    businessTypes: { type: Array, required: true, default: () => [] },
    type: { type: String, required: true, default: () => 'overview' },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      showFilters: false,
      selectedDealer: {},
      checkedParameters: false,
      filters: {
        businessTypes: [],
        country: null,
        locationLat: null,
        locationLng: null,
        productCategories: [],
        range: null,
        search: '',
        postalCode: '',
      },
      userCountry: null,
      ranges: [50, 100, 250, 500, 1000, 1500, 2000, 5000, 10000],
      preselectedCategories: ['Wheeled excavators'],
    };
  },

  computed: {
    /**
     * The countries that have one or more dealers associated
     * @returns {array} Filtered countries
     */
    countriesWithDealer() {
      // eslint-disable-next-line max-len
      return this.countries.filter(country => this.dealers.some(({ location }) => location && location.country && location.country === country.name));
    },

    /**
     * Create an object array of each dealer with just the location and guid property
     * @returns {array} Filtered dealers
     */
    dealerLocations() {
      return this.filteredDealers.map(({
        location, guid, name, url, contact,
      }) => ({
        location, guid, name, url, contact,
      }));
    },

    /**
     * Label shown to indicate what dealers are shown
     * @returns {string} with label text
     */
    dealerStatus() {
      return `${this.filteredDealers.length} ${this.dictionary.dealersFound}`;
    },

    /**
     * Filters dealers based on user filters
     * @returns {array} Filtered dealers
     */
    filteredDealers() {
      let filteredDealers = this.dealers;

      // Filter properties
      const { country, businessTypes, productCategories } = this.filters;

      // Filter by selected country (if any)
      if (country) {
        filteredDealers = this.filterDealersByCountry(filteredDealers, country);
      }

      // Filter by selected business types (if any)
      if (businessTypes.length > 0) {
        // eslint-disable-next-line max-len
        filteredDealers = filteredDealers.filter(dealer => this.arrayMatchesWithArray(businessTypes, dealer.services));
      }

      // Filter selected product categories (if any)
      if (productCategories.length > 0) {
        // eslint-disable-next-line max-len
        filteredDealers = filteredDealers.filter(dealer => this.arrayMatchesWithArray(productCategories, dealer.products));
      }

      // Sort by distance
      filteredDealers.sort((a, b) => a.location.distance - b.location.distance);

      return filteredDealers;
    },

    /**
     * Required so value can be 'watched'
     * @returns {string} Postal code
     */
    postalCode() {
      return this.filters.postalCode;
    },
  },

  created() {
    if (this.type === 'overview') {
      this.userCountry = this.getCountryBasedOnLanguage();
    } else if (this.type === 'country') {
      this.filters.country = this.country;
    } else if (this.type === 'detail') {
      this.selectedDealer = this.dealer;
    }
  },

  mounted() {
    if (this.type !== 'detail') {
      window.addEventListener('load', this.calculateHeight);
      window.addEventListener('resize', lodash.debounce(this.calculateHeight, 200));
    }
  },

  methods: {
    /**
     * Hide the filters, set the filters
     * @param {array} newFilter object with new filter data
     * @returns {undefined}
     */
    applyFilter(newFilter) {
      this.showFilters = false;

      this.filters = newFilter;

      // Reset selected dealer so no dealer is highlighted after filtering
      this.selectedDealer = {};
    },

    /**
     * Set the selected dealer
     * @returns {undefined}
     */
    onDealerSelect(dealer) {
      this.selectedDealer = dealer;
    },

    /**
     * Filter by selected country
     * @param {array} dealers to filter
     * @param {string} country country to filter
     * @returns {array} filtered dealers
     */
    filterDealersByCountry(dealers, country) {
      return dealers.filter(dealer => dealer.location.country === country);
    },

    /**
     * Calculate height for desktop based on top offset
     * Add additional offset to indicate the user can scroll down
     * If screen is smaller than desktop remove appended height
     * @returns {undefined}
     */
    calculateHeight() {
      if (viewport.is('>=lg')) {
        if (!this.$el.hasAttribute('style')) {
          const bodyRect = document.body.getBoundingClientRect();
          const elemRect = this.$el.getBoundingClientRect();
          const offsetTop = elemRect.top - bodyRect.top;

          const initialHeight = '100vh';
          // add offset to make sure the footer is still partly visible
          const additionalOffset = '30px';
          this.$el.style.height = `calc(${initialHeight} - ${offsetTop}px - ${additionalOffset})`;
        }
      } else if (this.$el.hasAttribute('style')) {
        this.$el.removeAttribute('style');
      }
    },

    /**
     * Match the country filter with the associated language
     * @returns {string} name of country
     */
    getCountryBasedOnLanguage() {
      const defaultLang = 'en';
      const languages = [
        { code: 'be', name: 'België' },
        { code: 'be', name: 'Belgique' },
        { code: 'nl', name: 'Netherlands' },
        { code: 'de', name: 'Germany' },
        { code: 'es', name: 'Spain' },
        { code: 'fr', name: 'France' },
        { code: 'it', name: 'Italy' },
        { code: 'pl', name: 'Poland' },
        { code: 'gb', name: 'United Kingdom' },
        { code: 'en', name: 'United Kingdom' },
        { code: 'eu', name: 'Other countries' },
      ];

      // Set initial country based on saved language cookie
      const langSetting = Cookies.get('hyundaiconstructionequipment#lang') || Cookies.get('constructionequipmentgolive#lang') || defaultLang;
      const langSettingStripped = langSetting.split('-')[0];

      let selectedLang = languages.find(lang => lang.code === langSettingStripped);
      if (!selectedLang) {
        selectedLang = languages.find(lang => lang.code === defaultLang);
      }

      return selectedLang && selectedLang.name;
    },

    /**
     * Generates a link to a destination with latitude and longitude
     * @param {object} dealer with the location
     * @returns {string} generated link
     */
    createDirectionsLink(dealer) {
      const { lat, lng } = dealer.location;
      return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    },

    /**
     * Checks if subset array exists in superset array
     * @param {array} superset Array to check
     * @param {array} subset Array values to find
     * @returns {boolean}
     */
    arrayMatchesWithArray(superset, subset) {
      const matchesWithSubset = value => subset.indexOf(value) !== -1;

      return superset.length === 1
        ? superset.some(matchesWithSubset)
        : superset.every(matchesWithSubset);
    },
  },
};
</script>
