var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "hce-loader",
      class: { "hce-loader--small": _vm.props.size === "small" },
    },
    [
      _c("div", { staticClass: "hce-loader__circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "hce-loader__circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "hce-loader__circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "hce-loader__circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "hce-loader__circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "hce-loader__circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "hce-loader__circle" }),
      _vm._v(" "),
      _c("div", { staticClass: "hce-loader__circle" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }