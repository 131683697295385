var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "hce-product-table" }, [
    _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "label", attrs: { colspan: "2" } }, [
          _vm._v("\n        " + _vm._s(_vm.category.name) + "\n      "),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.category.items, function (item, index) {
        return _c("tr", { key: index }, [
          _c("td", [_vm._v(_vm._s(item.label))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.calculateUnit(item.values)))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }