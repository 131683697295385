var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hce-image-slider no-container-gutters",
      attrs: { "data-dm": "component.image-slider" },
    },
    [
      _c("div", { staticClass: "hce-image-slider__carousel container" }, [
        _c("div", { staticClass: "hce-image-slider__header" }, [
          _c("h3", { staticClass: "mb-0" }, [
            _vm._v("\n        " + _vm._s(_vm.dictionary.title) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "hce-image-slider__carousel-inner" }, [
          _c("div", { ref: "image-carousel", staticClass: "glide" }, [
            _c(
              "div",
              {
                staticClass: "glide__track",
                attrs: { "data-glide-el": "track" },
              },
              [
                _c(
                  "ul",
                  { staticClass: "glide__slides" },
                  _vm._l(_vm.images, function (image, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "glide__slide" },
                      [
                        _c("div", { staticClass: "glide__slide-wrapper" }, [
                          _c("img", {
                            attrs: { src: image.url, alt: image.alt },
                          }),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "glide__arrows",
                attrs: { "data-glide-el": "controls" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "glide__arrow glide__arrow--left",
                    attrs: { "data-glide-dir": "<" },
                  },
                  [
                    _c("span", { staticClass: "icon icon--arrow-left" }, [
                      _c(
                        "svg",
                        { attrs: { role: "img", title: "previous slide" } },
                        [
                          _c("use", {
                            attrs: {
                              "xlink:href": "/assets/icons/ui.svg#arrow-left",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "glide__arrow glide__arrow--right",
                    attrs: { "data-glide-dir": ">" },
                  },
                  [
                    _c("span", { staticClass: "icon icon--arrow-right" }, [
                      _c(
                        "svg",
                        { attrs: { role: "img", title: "next slide" } },
                        [
                          _c("use", {
                            attrs: {
                              "xlink:href": "/assets/icons/ui.svg#arrow-right",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "glide__bullets--wrapper d-flex" }, [
              _c("label", { staticClass: "mr-2" }, [_vm._v("IMAGE")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "glide__bullets",
                  attrs: { "data-glide-el": "controls[nav]" },
                },
                _vm._l(_vm.images, function (image, index) {
                  return _c(
                    "button",
                    {
                      key: index,
                      staticClass: "glide__bullet label mr-2",
                      attrs: { "data-glide-dir": index },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(index + 1) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("label", {
                ref: "counted-images",
                staticClass: "js-counted-imgs",
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }