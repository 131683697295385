var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "hce-image-gallery no-container-gutters",
      attrs: { "data-dm": "component.image-gallery" },
    },
    [
      _c("div", { staticClass: "hce-image-gallery__carousel container" }, [
        _c("div", { staticClass: "hce-image-gallery__header" }, [
          _c("h3", { staticClass: "mb-0" }, [
            _vm._v("\n        " + _vm._s(_vm.dictionary.title) + "\n      "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hce-image-gallery__controls-md" },
            [
              _c("hce-control-arrows", {
                attrs: {
                  "next-disabled": _vm.isAtEnd,
                  "prev-disabled": _vm.isAtBeginning,
                },
                on: {
                  next: _vm.nextCarouselSlide,
                  prev: _vm.prevCarouselSlide,
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "hce-image-gallery__slides slides-container" },
          [
            _c(
              "div",
              { ref: "swiper-container", staticClass: "swiper-container" },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  _vm._l(_vm.images, function (image, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "hce-image-gallery__slide swiper-slide",
                        attrs: { "data-dm": "btn-image-open" },
                      },
                      [
                        _c("img", {
                          attrs: { src: image.url, alt: image.alt },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "hce-image-gallery__zoom-in",
                            on: { keypress: _vm.onSelectImage },
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "icon",
                                attrs: {
                                  role: "img",
                                  title: "Zoom in image " + (index + 1),
                                },
                              },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/assets/icons/ui.svg#zoom-in",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "hce-image-gallery__scroll-shadow",
                  class: { "is-hidden": _vm.isAtEnd },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "hce-image-gallery__controls-sm" },
          [
            _c("hce-control-arrows", {
              attrs: {
                "next-disabled": _vm.isAtEnd,
                "prev-disabled": _vm.isAtBeginning,
              },
              on: { next: _vm.nextCarouselSlide, prev: _vm.prevCarouselSlide },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showOverlay,
              expression: "showOverlay",
            },
          ],
          staticClass: "image-gallery-overlay",
          attrs: {
            id: "dialog",
            role: "dialog",
            "aria-labelledby": "carousel dialog",
            "aria-modal": "true",
          },
        },
        [
          _c("div", [
            _c(
              "button",
              {
                ref: "dialog-close-btn",
                staticClass: "image-gallery-overlay__close",
                attrs: {
                  "data-dm": "btn-image-close",
                  "aria-label": "close dialog",
                },
                on: { click: _vm.closeOverlay },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: { role: "img", title: "Close overlay" },
                  },
                  [
                    _c("use", {
                      attrs: { "xlink:href": "/assets/icons/ui.svg#close" },
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("button", {
              ref: "prev-overlay",
              staticClass: "swiper-button-prev swiper-button-white",
            }),
            _vm._v(" "),
            _c("button", {
              ref: "next-overlay",
              staticClass: "swiper-button-next swiper-button-white",
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "swiper-overlay-container",
              staticClass: "swiper-container",
            },
            [
              _c(
                "div",
                {
                  staticClass: "swiper-wrapper",
                  on: { click: _vm.handleClickOutsideCarouselImage },
                },
                _vm._l(_vm.images, function (image, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "image-gallery-overlay__slide swiper-slide",
                    },
                    [_c("img", { attrs: { src: image.url, alt: image.alt } })]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", {
                ref: "overlay-pagination",
                staticClass: "swiper-pagination swiper-pagination-white",
              }),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-pagination" }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }