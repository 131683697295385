var render = function render(_c, _vm) {
  return _c("div", { staticClass: "hce-arrow-controls" }, [
    _c(
      "button",
      _vm._g(
        {
          staticClass: "hce-arrow-controls__btn btn",
          attrs: {
            "data-dm": "btn-image-left-arrow",
            disabled: _vm.props.prevDisabled,
          },
        },
        _vm.listeners.prev ? { click: _vm.listeners.prev } : {}
      ),
      [
        _c("span", { staticClass: "icon icon--arrow-left" }, [
          _c("svg", { attrs: { role: "img", title: "previous slide" } }, [
            _c("use", {
              attrs: { "xlink:href": "/assets/icons/ui.svg#arrow-left" },
            }),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      _vm._g(
        {
          staticClass: "hce-arrow-controls__btn btn",
          attrs: {
            "data-dm": "btn-image-right-arrow",
            disabled: _vm.props.nextDisabled,
          },
        },
        _vm.listeners.next ? { click: _vm.listeners.next } : {}
      ),
      [
        _c("span", { staticClass: "icon icon--arrow-right" }, [
          _c("svg", { attrs: { role: "img", title: "next slide" } }, [
            _c("use", {
              attrs: { "xlink:href": "/assets/icons/ui.svg#arrow-right" },
            }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }