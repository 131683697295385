<template>
  <div
    class="hce-article-overview"
    data-dm="component.article-overview"
  >
    <header class="overview-header">
      <div class="container">
        <h1 class="overview-title display-1">
          {{ dictionary.title }}
        </h1>
      </div>
    </header>
    <main>
      <div class="half-background">
        <div class="container">
          <section class="highlighted-cards">
            <div
              v-for="(article, index) in overview.results.highlightedArticles"
              :key="index"
              class="col-md-6"
            >
              <hce-article-card
                :article="article"
                type="highlighted"
              />
            </div>
          </section>
        </div>
      </div>

      <div class="container">
        <transition-group
          v-masonry
          name="cards"
          tag="section"
          class="regular-cards-masonry"
          item-selector=".masonry-item"
          transition-duration="0"
        >
          <div
            v-for="(article, index) in overview.results.articles"
            :key="index"
            v-masonry-tile
            class="masonry-item"
          >
            <hce-article-card
              :article="article"
              type="regular"
            />
          </div>
        </transition-group>
        <div
          v-if="overview.results.articles.length < overview.results.articleCount"
          class="load-more-cards"
        >
          <button
            class="load-more-button btn btn-secondary btn-lg"
            role="button"
            @click="loadArticlesForNextPage"
          >
            <span class="btn__label">{{ dictionary.loadMore }}</span>
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import 'url-search-params-polyfill';
import { axios, lazyLoader } from 'foundation-theming';
import HceArticleCard from './HceArticleCard.vue';

export default {
  components: {
    HceArticleCard,
  },

  props: {
    dictionary: { type: Object, default: () => { } },
    initialArticles: { type: Object, required: true, default: () => [] },
    resultsOnPage: { type: Number, default: 12 },
  },

  data() {
    return {
      overview: this.initialArticles,
      pageNumber: 1,
    };
  },

  created() {
    this.pageNumber = this.getPageNumberFromQuerystring(document.location.search);
  },

  mounted() {
    this.onLoad();
  },

  methods: {
    /**
     * Wait before recalculating the masonry so all CSS is loaded
     */
    onLoad() {
      new Promise(resolve => setTimeout(resolve, 950))
        .then(() => this.recalculateMasonry());
    },

    /**
     * Use the Vue Masonry plugin to recalculate the columns based on config set in mounted
     */
    recalculateMasonry() {
      this.$redrawVueMasonry();
    },

    /**
     * Fetch more articles for next page and add them to existing masonry
     * @returns {Promise} after articles loaded
     */
    loadArticlesForNextPage() {
      this.pageNumber += 1;

      return this.getArticles()
        .then((response) => {
          const newArticles = response.data.results.articles;

          this.overview.results.articles = [
            ...this.overview.results.articles,
            ...newArticles,
          ];

          this.updatePageQuery(this.pageNumber);

          this.$nextTick(() => {
            this.recalculateMasonry();
            // reobserve lazy images
            lazyLoader.observe();
          });
        });
    },

    /**
     * Make post request to obtain articles from certain page
     * @returns {Promise} with articles result
     */
    getArticles() {
      const url = `/api/feature/news/pagedsearch?page=${this.pageNumber}`
        + `&resultsOnPage=${this.resultsOnPage}`
        + `&itemId=${this.overview.results.itemId}`;

      return axios.post(url);
    },

    /**
     * Update the page property based on the querystring from the URL
     * @returns {number} page obtained, default 1
     */
    getPageNumberFromQuerystring(querystring) {
      if (!querystring) return 1;
      const params = new URLSearchParams(querystring);
      return parseInt(params.get('page'), 10) || 1;
    },

    /**
     * Update the URL querystring with page number
     * @param {number} page number
     */
    updatePageQuery(page) {
      const params = new URLSearchParams(document.location.search);
      params.set('page', page);
      history.replaceState({ page }, '', `?${params.toString()}`);
    },
  },
};
</script>
